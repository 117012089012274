// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
    margin: 0;
    font-size: 23px;
    color: rgb(153, 153, 153);
}

body {
    background-color: rgb(18, 18, 18);
    color: white;
    margin: auto;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.intro, .projects-showcase {
    margin-bottom: 60px;
}

.container {
    margin-top: 40px;
    max-width: 80ch;
    margin: auto;
    padding: 0 40px;
}

h1 {
    font-weight: 600;
    font-size: 35px;
}

.header {
    height: 130px;
}

.projects-showcase {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.project-item {
    background-color: rgb(30, 30, 30);
    padding: 20px 15px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

a {
    color: white;
    font-size: 21px;
}

.github-icon {
    border-right: 1.5px solid rgb(96, 96, 96);
}

img {
    width: 23px;
    margin-right: 10px;
    vertical-align: text-bottom;
}

.contact {
    margin-bottom: 100px;
}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,iCAAiC;IACjC,YAAY;IACZ,YAAY;IACZ,mJAAmJ;AACvJ;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,2BAA2B;AAC/B;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".text {\n    margin: 0;\n    font-size: 23px;\n    color: rgb(153, 153, 153);\n}\n\nbody {\n    background-color: rgb(18, 18, 18);\n    color: white;\n    margin: auto;\n    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n}\n\n.intro, .projects-showcase {\n    margin-bottom: 60px;\n}\n\n.container {\n    margin-top: 40px;\n    max-width: 80ch;\n    margin: auto;\n    padding: 0 40px;\n}\n\nh1 {\n    font-weight: 600;\n    font-size: 35px;\n}\n\n.header {\n    height: 130px;\n}\n\n.projects-showcase {\n    display: flex;\n    gap: 20px;\n    flex-wrap: wrap;\n}\n\n.project-item {\n    background-color: rgb(30, 30, 30);\n    padding: 20px 15px;\n    border-radius: 7px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 10px;\n}\n\na {\n    color: white;\n    font-size: 21px;\n}\n\n.github-icon {\n    border-right: 1.5px solid rgb(96, 96, 96);\n}\n\nimg {\n    width: 23px;\n    margin-right: 10px;\n    vertical-align: text-bottom;\n}\n\n.contact {\n    margin-bottom: 100px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
